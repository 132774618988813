import React from "react";
import { graphql } from "gatsby";
import ReactMarkdown from "react-markdown";
import Layout from "../components/App/Layout";
import Navbar from "../components/App/Navbar";
import Footer from "../components/App/Footer";

const ComponentName = ({ data }) => {
  const { title, content } = data.blog;
  return (
    <Layout>
      <Navbar />
      <div className="page">
        <section className="blog-details-area">
          <div className="container">
            <div className="blog-header">
              <h1>{title}</h1>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="blog-details-desc">
                  <ReactMarkdown
                    source={content}
                    transformImageUri={(uri) =>
                      uri.startsWith("http")
                        ? uri
                        : `${process.env.IMAGE_BASE_URL}${uri}`
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <Footer />
    </Layout>
  );
};

export const query = graphql`
  query GetSingleBlog($slug: String) {
    blog: strapiBlogs(slug: { eq: $slug }) {
      title
      content
    }
  }
`;

export default ComponentName;
